import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "DocumentAttachment";
const documentAttachmentService = {
  get(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        `${resource}/holderKey/${model.holderKey}/pageIndex/${model.pageIndex}/pageSize/${model.pageSize}`
      );
    }
  },

  getByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`${resource}/documentKey`, key);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },

  update(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(`${resource}/UpdateDocument/?documentkey=${data.document_key}`, null, data);
    }
  },

  delete(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}/documentKey/${key}`, key);
    }
  },
};

export default documentAttachmentService;
