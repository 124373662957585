import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const baseUrl = process.env.VUE_APP_API_BASE_DEPOSIT_URL;
const fileUrl =
  `${baseUrl}GeneralDocument/CreateAttachment`;

const fileUploadService = {
  uploadFile(account_id, formData, title) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.postFormData(
        `${fileUrl}/${account_id}?accountType=Underwriting&documentTitle=${title}`,
        formData
      );
    }
  },
};

export default fileUploadService;
