import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import GeneralApiService from '@/core/services/general.service';

import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

Vue.use(VueAxios, axios);

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "comment";
const link = process.env.VUE_APP_API_BASE_LOAN_URL;

const idTemplatesService = {
  getByParentKey(key) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return GeneralApiService.get(`${resource}/${key}`, null);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return GeneralApiService.post(`${link}LoanAccount/create-loanaccount-comment`, data);
    }
  },

  put(data) {
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return GeneralApiService.put(`${link}LoanAccount/edit-loanaccount-comment`, data);
    }
  },

  update(id, data) {
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return GeneralApiService.update(resource, id, data);
    }
  },

  del(params) {
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return Vue.axios.delete(`${link}/delete-loanaccount-comment`, {
        data: {
          ...params
        }
      });
    }
  },

  delete(id) {
    if (JwtService.getToken()) {
      GeneralApiService.setHeader();
      return ApiService.delete(`${resource}/${id}`);
    }
  },
};

export default idTemplatesService;
